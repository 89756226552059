import React from "react";
import styled from "styled-components";

import ContentLayout from "Components/ContentLayout";
import ListGroup from "Components/ListGroup";

const Wrapper = styled.div`
  padding: 32px;
`;

class ProjectIntegrations extends React.Component {
  render() {
    return (
      <ContentLayout>
        <h4 id="integrations-heading">Integrations</h4>
        <ListGroup aria-labelledby="integrations-heading">
          <Wrapper>Integration Information.</Wrapper>
        </ListGroup>
      </ContentLayout>
    );
  }
}

export default ProjectIntegrations;
